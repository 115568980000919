<template>
  <basedetail
    v-if="refreshComp"
    :title="title"
    :models="model"
    :fields="field"
    :nameroute="nameroute"
    :edit="btnEdit"
  >
    <template v-slot:print>
      <div v-if="model.approval_status == 'STCPT'">
        <button
          type="button"
          class="float-end btn btn-dark mr-2"
          :data-kt-indicator="loadingprint ? 'on' : null"
          ref="tPrint"
          @click="btnPrint"
        >
          <span class="indicator-label"
            ><i class="fa fa-print"></i> Print
          </span>
          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <br /><br /><br />
      </div>
    </template>
    <template v-slot:department_procurement>
      <span class="fw-bolder fs-6 text-dark">
        {{
          model.department_procurement === null ||
          model.department_procurement === ""
            ? "-"
            : model.department_procurement
        }}</span
      >
    </template>
    <template v-slot:vendor_phone>
      <span class="fw-bolder fs-6 text-dark">
        {{
          model.vendor_phone === null || model.vendor_phone === ""
            ? "-"
            : model.vendor_phone
        }}</span
      >
    </template>
    <template v-slot:path_attachment>
      <!-- <b>
        <a
          href="javascript:void(0)"
          @click="btnDownloadFile(model.path_attachment)"
          title="Lihat File"
          >Download File</a
        >
      </b> -->

      <button
        type="button"
        class="btn btn-sm btn-dark mr-2"
        :data-kt-indicator="loadingDownload ? 'on' : null"
        ref="tPrint"
        @click="btnDownloadFile(model.path_attachment)"
      >
        <span class="indicator-label"> Download File</span>
        <span class="indicator-progress">
          Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </template>

    <template v-slot:tabel>
      <Datatable
        v-if="refreshTable"
        ref="basetable"
        :table-header="tabelHeader"
        :listData="model.po_details"
      >
        <template v-slot:cell-pr_code="{ row: data }">
          {{ data.pr_code }}
        </template>
        <template v-slot:cell-branch_name="{ row: data }">
          {{ data.branch }}
        </template>
        <template v-slot:cell-departement_name="{ row: data }">
          {{ data.department_name }}
        </template>
        <template v-slot:cell-updated_date="{ row: data }">
          {{ $mix.format_date_time(data.updated_date) }}
        </template>
        <template v-slot:cell-product_name="{ row: data }">
          {{ data.product_name }}
        </template>
        <template v-slot:cell-product_id="{ row: data }">
          {{ data.product_id }}
        </template>
        <template v-slot:cell-asset="{ row: data }">
          {{ data.asset }}
        </template>
        <template v-slot:cell-lob_name="{ row: data }">
          {{ data.lob_name }}
        </template>
        <template v-slot:cell-stock_on_hand="{ row: data }">
          {{ data.stock_on_hand }}
        </template>
        <template v-slot:cell-unit_price="{ row: data }">
          {{ $mix.formatCurrency(data.unit_price, "IDR") }}
        </template>
        <template v-slot:cell-unit_id="{ row: data }">
          {{ data.unit_id }}
        </template>
        <template v-slot:cell-sub_total="{ row: data }">
          {{ $mix.formatCurrency(data.sub_total, "IDR") }}
        </template>
        <template v-slot:cell-qty="{ row: data }">
          {{ data.qty }}
        </template>

        <template v-slot:cell-vendor_kurir_id="{ row: data }">
          <template
            v-if="
              data.is_enable_vendor_kurir != 1 && this.enableVendorKurir == true
            "
          >
            {{
              data.vendor_kurir_id == "" || data.vendor_kurir_id == null
                ? "-"
                : data.vendor_name
            }}
            &nbsp;&nbsp;&nbsp;
            <a
              href="javascript:void(0)"
              @click="
                btnEditVendorKurir(
                  data.id,
                  data.branch_id,
                  data.product_id,
                  data.vendor_kurir_id
                )
              "
              ><span class="fa fa-edit"></span
            ></a>
          </template>
          <template v-else>
            {{
              data.vendor_kurir_id == "" || data.vendor_kurir_id == null
                ? "-"
                : data.vendor_name
            }}
          </template>
        </template>
      </Datatable>
    </template>
    <template v-slot:list_resi>
      <div class="col-md-12">
        <div class="row">
          <!-- <div class="col-md-6 px-4 mb-7">
            <div class="row">
              <label class="fw-bold" style="font-size: 14px">Cabang</label>
              <br />
              <el-select
                v-model="formStatus.branch_id"
                placeholder="Pilih Cabang"
                filterable
                v-bind:id="'selectIdBranch'"
                clearable
                :popper-class="'popperSelectIdBranch'"
              >
                <el-option
                  v-for="(nilai, index) in listBranch"
                  :key="index"
                  :label="nilai.label"
                  :value="nilai.value"
                />
              </el-select>
            </div>
          </div> -->
          <div class="col-md-12 px-4 mb-7">
            <div class="row" v-if="this.formStatus.status === 'STSHP'">
              <label class="fw-bold required" style="font-size: 14px">Nomor Resi</label>
              <br />
              <el-input
                v-bind:id="'vendorPhone'"
                @input="setMaxResi()"
                type="text"
                v-model="formStatus.receive_number"
                placeholder="Masukkan Nomor Resi"
              ></el-input>
            </div>
            <div class="row" v-else>
              <label class="fw-bold required" style="font-size: 14px">Nomor Resi</label>
              <br />
              <el-input
                v-bind:id="'vendorPhone'"
                @input="setMaxResi()"
                type="text"
                v-model="formStatus.receive_number"
                placeholder="Masukkan Nomor Resi Ketika Status Shipping"
                disabled
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:list_total>
      <!-- <span class="fw-bolder fs-6 text-dark">{{$mix.formatCurrency(model.grand_total,'IDR')}}</span> -->
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 px-4 mb-7">
            <div class="row">
              <label class="col-lg-6 fw-bold text-muted">Diskon</label>
              <div class="col-lg-6">
                <span class="fw-bolder fs-6 text-dark">{{
                  $mix.formatCurrency(model.discount, "IDR")
                }}</span>
              </div>
              <hr class="my-2" />
            </div>
          </div>

          <div class="col-md-6 mb-7">
            <div class="row">
              <label class="col-lg-6 fw-bold text-muted">Total Harga PO</label>
              <div class="col-lg-6">
                <span class="fw-bolder fs-6 text-dark">{{
                  $mix.formatCurrency(model.grand_total, "IDR")
                }}</span>
              </div>
              <hr class="my-2" />
            </div>
          </div>

          <div class="col-md-6 px-4 mb-7">
            <div class="row">
              <label class="col-lg-6 fw-bold text-muted"
                >Total Pengajuan PO</label
              >
              <div class="col-lg-6">
                <span class="fw-bolder fs-6 text-dark">{{
                  $mix.formatCurrency(model.total_after_ppn, "IDR")
                }}</span>
              </div>
              <hr class="my-2" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:production_vendor_notes v-if="isNoteProduction">
      <h4 class="mb-6"> Note Vendor Produksi</h4>

      <div class="row">
        <div class="col-md-12 px-4 mb-7">
          <div class="row">
            <label class="fw-bold required" style="font-size: 14px">Note</label>
            <br />

            <el-input class="inline-input w-100" v-model="formStatus.production_vendor_notes" type="textarea" maxlength="255" show-word-limit :disabled="isDisableNoteProduction" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:courier_vendor_notes v-if="isNoteCourier">
      <h4 class="mb-6"> Note Vendor Kurir</h4>

      <div class="row">
        <div class="col-md-12 px-4 mb-7">
          <div class="row">
            <label class="fw-bold required" style="font-size: 14px">Note</label>
            <br />
            
            <el-input class="inline-input w-100" v-model="formStatus.courier_vendor_notes" type="textarea" maxlength="255" show-word-limit :disabled="isDisableNoteCourier" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:order_received_time v-if="isDateOrderArrive">
      <h4 class="mb-6"> Waktu Pesanan Tiba</h4>

      <div class="row">
        <div class="col-md-12 px-4 mb-7">
          <div class="row">
            <label class="fw-bold required" style="font-size: 14px">Waktu Pesanan Tiba</label>
            <br />

            <el-date-picker
              v-if="!isDisableOrderArrive"
              class="inline-input w-100"
              v-model="formStatus.order_received_time"  
              type="datetime"
              placeholder="Waktu Pesanan Tiba"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              :disabled-date="disabledDateOrderReceivedTime"
            />

            <el-date-picker
              v-else
              class="inline-input w-100"
              v-model="formStatus.order_received_time"  
              type="datetime"
              placeholder="Waktu Pesanan Tiba"
              format="YYYY-MM-DD HH:mm:ss"
              disabled
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:update_status>
      <hr class="my-2" />
      <h4 class="mb-6">Pembaruan Status</h4>

      <div class="row">
        <div class="col-md-12 px-4 mb-7">
          <div class="row">
            <label class="fw-bold" style="font-size: 14px">Status</label>
            <br />
            <el-select
              v-model="formStatus.status"
              filterable
              placeholder="Pilih Status"
              v-bind:id="'inputApproval'"
              :popper-class="'ApprovalSelect'"
              :disabled="disableStatus"
            >
              <el-option
                v-for="item in isVendorStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>

      <!-- &nbsp; &nbsp; &nbsp; &nbsp; -->
      <div class="mt-6" align="right">
        <button
          :data-kt-indicator="loading ? 'on' : null"
          class="btn btn-dark me-3"
          type="submit"
          id="btnSimpan"
          ref="btnSimpan"
          @click="submitUpdatePO()"
          v-if="this.disableStatus === false"
        >
          <span v-if="!loading" class="indicator-label">
            Simpan
            <span class="svg-icon svg-icon-3 ms-2 me-0">
              <inline-svg src="icons/duotune/arrows/arr064.svg" />
            </span>
          </span>
          <span v-if="loading" class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>

      <!-- disini untuk combo box nya update status -->
    </template>
    <template v-slot:riwayat>
      <hr class="my-2" />
      <h4 class="mb-6">Riwayat Status PO</h4>
      <Datatable
        v-if="refreshTableHistory"
        ref="basetablehistory"
        :table-header="tabelHeaderHistory"
        :listData="model.history"
      >
        <template v-slot:cell-status="{ row: data }">
          {{ data.status }}
        </template>
        <template v-slot:cell-updated_date="{ row: data }">
          {{ $mix.format_date_hour_minute(data.updated_date) }}
        </template>
      </Datatable>

      <el-dialog
        title="Edit Vendor Kurir"
        v-model="dialogVisibleDialogVendorKurir"
        width="30%"
        custom-class="styleDialogStok"
      >
        <div class="row">
          <div class="col-md-12 px-4 mb-7">
            <div class="row">
              <label class="fw-bold" style="font-size: 14px"
                >Nama Vendor Kurir</label
              >
              <br />
              <el-select
                @click="selectVendorKurir"
                v-model="vendor_kurir_id"
                filterable
                placeholder="Pilih Nama Vendor Kurir"
                :remote-method="cariVendorKurir"
                @change="handleSelect($event, 'vendor_kurir')"
                remote
                v-bind:id="'inputVendorKurir'"
                :popper-class="'vendorSelectKurir'"
              >
                <el-option
                  v-for="item in lsVendorKurir"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
        </div>

        <template v-slot:footer>
          <span class="dialog-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-dark mr-2"
              @click="btnSaveVendorKurir(this.tempIdVendorKurir.id)"
            >
              Simpan</button
            >&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              type="button"
              class="btn btn-light"
              @click="dialogVisibleDialogVendorKurir = false"
            >
              Batal
            </button>
          </span>
        </template>
      </el-dialog>
    </template>
  </basedetail>
  <template>
        <div id="divCon">  
        </div>
    </template>
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import basedetail from "@/views/base/BaseDetail.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CryptoJS from "crypto-js";
import sha256 from "crypto-js/sha256";
import { tryOnScopeDispose } from "@vueuse/shared";
import moment, { defaultFormat } from "moment";

export default {
  components: {
    basedetail,
    Datatable,
    CryptoJS,
    sha256,
  },
  data() {
    const list = [
      { key: "pr_code", name: "NOMOR PR", sortable: true },
      { key: "branch_name", name: "CABANG", sortable: true },
      { key: "departement_name", name: "Departemen", sortable: true },
      { key: "updated_date", name: "TANGGAL DISETUJUI", sortable: true },
      { key: "product_name", name: "Nama PRODUK", sortable: true },
      { key: "product_id", name: "ID PRODUK", sortable: true },
      { key: "asset", name: "Asset - Kategori", sortable: true },
      { key: "unit_price", name: "Harga Satuan", sortable: true },
      { key: "unit_id", name: "Satuan", sortable: true },
      { key: "stock_on_hand", name: "Stock On Hand", sortable: true },
      { key: "lob_name", name: "LoB", sortable: true },
      { key: "qty", name: "Order Qty", sortable: true },
      { key: "sub_total", name: "Total Harga", sortable: true },
      { key: "vendor_kurir_id", name: "Vendor Kurir", sortable: true },
    ];
    const listHistory = [
      {
        key: "status",
        name: "Status",
        sortable: true,
      },
      {
        key: "updated_date",
        name: "Tanggal Perubahan Terakhir",
        sortable: true,
      },
    ];
    return {
      title: "",
      nameroute: "dashboard",
      tabelHeader: list,
      tabelHeaderHistory: listHistory,
      loading: false,
      loadingprint: false,
      loadingDownload: false,
      isSubmitStatusPO: false,
      field: [
        {
          key: "print",
          custom: true,
          row: true,
        },
        {
          key: "po_code",
          label: "Nomor PO",
          row: true,
        },
        {
          key: "department_procurement",
          label: "Departemen Penyedia",
          row: true,
          custom: true,
        },
        {
          key: "vendor_type_id",
          label: "Tipe Vendor",
          row: true,
        },
        {
          key: "vendor_name",
          label: "Nama Vendor",
          row: true,
        },
        {
          key: "ppn",
          label: "PPn",
          row: true,
          custom: true,
        },
        {
          key: "jenis_pajak_name",
          label: "PPh",
          row: true,
          custom: true,
        },
        {
          key: "jenis_perhitungan_pajak",
          label: "Jenis Perhitungan Pajak",
          row: true,
          custom: true,
        },
        {
          key: "nomor_rekening",
          label: "Nomor Rekening",
          row: true,
        },
        {
          key: "bank_name",
          label: "Bank",
          row: true,
        },
        {
          key: "kantor_cabang_bank",
          label: "Cabang Bank",
          row: true,
        },
        {
          key: "vendor_phone",
          label: "Telepon",
          row: true,
          custom: true,
        },
        {
          key: "path_attachment",
          label: "Lampiran Dokumen",
          row: true,
          custom: true,
        },
        {
          key: "reason",
          label: "Catatan",
          row: true,
        },
        {
          key: "tabel",
          custom2: true,
          row: true,
        },
        {
          key: "list_resi",
          custom2: true,
          row: true,
        },
        {
          key: "list_total",
          custom2: true,
          row: true,
        },
        {
          key: "production_vendor_notes",
          custom2: true,
          row: true,
        },
        {
          key: "courier_vendor_notes",
          custom2: true,
          row: true,
        },
        {
          key: "order_received_time",
          custom2: true,
          row: true,
        },
        {
          key: "update_status",
          custom2: true,
          row: true,
        },
        {
          key: "riwayat",
          custom2: true,
          row: true,
        },
      ],
      listData: [],
      listDataHistory: [],
      isVendorStatus: [],
      formStatus: {
        pr_code: "",
        po_code: "",
        status: "",
        branch_id: "",
        receive_number: "",
        po_details: [
          // {
          //   id: "",
          //   vendor_kurir_id: "",
          // },
        ],
        production_vendor_notes: "",
        courier_vendor_notes: "",
        // order_received_time: [],
      },
      defaultTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      vendor_kurir_id: "",
      tempIdVendorKurir: {
        id: null,
        branch_id: null,
        product_id: null,
      },

      refreshComp: true,
      refreshTable: true,
      refreshTableHistory: true,
      dialogVisibleDialogVendorKurir: false,
      model: {
        ppn_total: 0,
        approval_status: "",
        id_fix: "",
        vendor_kurir_id: "",
      },
      disableStatus: false,
      enableVendorKurir: false,
      listDept: [],
      listBranch: [],
      lsVendorKurir: [],
      approval: {
        po_code: "",
        approval_status: "",
        note: "",
      },
      pr_code_kurir: "",
      vendor_name: "",
      tmbSimpan: false,
      btnEdit: false,
      attachment_file_name: "",
      profile: JSON.parse(this.$store.getters.currentUser),
      status_po_production: "",
      status_po_courier: "",
      disabledDateOrderReceivedTime(time) {
        const today = new Date();
        const maxDate = new Date(today.setDate(today.getDate() - 2));
        return time.getTime() < maxDate.getTime();
      },
    };
  },
  mounted() {
    if (this.profile.data.vendor_type_name === "PRODUKSI") {
      this.title = "Detail Purchase Order Produksi";
      setCurrentPageBreadcrumbs("Detail Purchase Order Produksi", [
        {
          title: "List Purchase Order",
          link: "/purchase-order",
        },
      ]);
    } else {
      this.title = "Detail Purchase Order Kurir";
      setCurrentPageBreadcrumbs("Detail Purchase Order Kurir", [
        {
          title: "List Purchase Order",
          link: "/purchase-order",
        },
      ]);
    }

    // this.fetchBranch();
    this.fetchStatusVendor();

    var paramss = { vendor_type: this.profile.data.vendor_type_id };

    // start x-signature-key
    var sha256 = CryptoJS.algo.SHA256.create();
    sha256.update(JSON.stringify(paramss));
    var hash = sha256.finalize();
    var hashupper = hash.toString().toUpperCase();

    localStorage.setItem("signature_key", hashupper);
    // end x-signature-key

    if (this.profile.data.vendor_type_name === "KURIR") {
      this.pr_code_kurir = localStorage.getItem("pr_code_kurir");
      this.formStatus.pr_code = localStorage.getItem("pr_code_kurir");
      this.fetchDataKurir();
    } else {
      this.fetchDataProduksi();
    }
  },
  computed: {
    isNoteProduction() {
      return this.status_po_production !== null && this.status_po_production !== "STRJT" && this.status_po_production !== "STACT";
    },
    isNoteCourier() {
      return this.profile.data.vendor_type_name === "KURIR";
    },
    isDateOrderArrive() {
      return this.profile.data.vendor_type_name === "KURIR" && this.status_po_courier !== "STRTP" && this.status_po_courier !== "STPCU";
    },
    isDisableNoteProduction() {
      return this.status_po_production !== null && this.status_po_production !== "STRJT" && this.status_po_production !== "STACT" && this.status_po_production !== "STOPG";
    },
    isDisableNoteCourier() {
      return this.status_po_courier !== "STRTP";
    },
    isDisableOrderArrive() {
      return this.status_po_courier === "STDVD";
    },
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    btnPrint() {
      var paramss = { vendor_type: this.profile.data.vendor_type_id };

      this.loadingprint = true;
      this.$refs.tPrint.setAttribute("data-kt-indicator", "on");
      this.$refs.tPrint.disabled = true;
      // start x-signature-key
      var sha256 = CryptoJS.algo.SHA256.create();
      sha256.update(JSON.stringify(paramss));
      var hash = sha256.finalize();
      var hashupper = hash.toString().toUpperCase();

      localStorage.setItem("signature_key", hashupper);
      // end x-signature-key
      this.$apiservice.setHeader();
      this.$apiservice
        .query("/purchaseorder/v1/print", {
          params: { poCode: this.$route.params.id, prCode: this.pr_code_kurir },
        })
        .then((res) => {
          var contentOfDiv = document.getElementById("divCon").innerHTML;
          var newWin = window.open('', '', 'height=650, width=1200');
          newWin.document.write(contentOfDiv);
          newWin.document.write(res.data);
          setTimeout(function () {
            newWin.document.close();
            newWin.focus();
            newWin.print();
            newWin.close();
          }, 1000);
        })
        .finally(() => {
          this.loadingprint = false;
          this.$refs.tPrint.removeAttribute("data-kt-indicator");
          this.$refs.tPrint.disabled = false;
        });
    },

    btnDownloadFile(link) {
      this.loadingDownload = true;
      this.$refs.tPrint.setAttribute("data-kt-indicator", "on");
      this.$refs.tPrint.disabled = true;

      this.$apiservice.setHeader();
      this.$apiservice
        .query("/reference/v1/get-file", { params: { Url: link } })
        .then((res) => {
          const linkSource = `data:application/pdf;base64,${res.data.message}`;
          const downloadLink = document.createElement("a");
          const fileName = this.attachment_file_name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .finally(() => {
          this.loadingDownload = false;
          this.$refs.tPrint.removeAttribute("data-kt-indicator");
          this.$refs.tPrint.disabled = false;
        });
    },

    fetchDataProduksi() {
      this.$apiservice.setHeader();
      this.$apiservice
        .query("/purchaseorder/v1/get-detail", {
          params: { PoCode: this.$route.params.id },
        })
        .then((res) => {  
          this.status_po_production = res.data.details[0].status_vendor
          this.formStatus.production_vendor_notes = res.data.production_vendor_notes
          this.formStatus.courier_vendor_notes = res.data.courier_vendor_notes
          this.formStatus.order_received_time = res.data.order_received_time
          this.disableStatus =
            res.data.enable_update_status === true ? false : true;

          this.enableVendorKurir = res.data.enable_update_vendor_kurir;

          this.attachment_file_name = res.data.attachment_file_name;

          this.model = {};
          let data = res.data;
          for (var i in this.field) {
            let row = this.field[i];
            for (var j of Object.keys(data.header)) {
              if (row.key == j) {
                this.model[j] = data.header[j];
              }
            }
          }
          if (data.details.length > 0) {
            this.model.po_details = [];
            this.listData = [];
            for (var i in data.details) {
              let row = data.details[i];

              row["asset"] = row.asset_code + "-" + row.asset_name;
              // row["id"] = data.details.length + 1;
              if (row["stock_on_hand"] == null) {
                row["stock_on_hand"] = "-";
              }
              this.listData.push({ ...row });
            }
            this.model.po_details = this.listData;
            // this.model.id_fix = res.data.details[0].id;
            // this.model.vendor_kurir_id = res.data.details[0].vendor_kurir_id;
          }

          if (data.history.length > 0) {
            this.model.history = [];
            this.listData = [];
            this.listDataHistory = [];
            for (var i in data.history) {
              let rowHistory = data.history[i];
              this.listDataHistory.push({ ...rowHistory });
            }
            this.model.history = this.listDataHistory;
          }

          this.model["branch_id"] = data.header["branch_id"];
          this.model["ppn"] = data.header["ppn"];
          this.model["discount"] = data.header["discount"];
          this.model["grand_total"] = data.header["grand_total"];
          this.model["ppn_total"] =
            (data.header["ppn"] / 100) * data.header["grand_total"];
          this.model["total_after_ppn"] = data.header["total_after_ppn"];
          this.model["approval_status"] = data.header["approval_status"];
          this.approval["approval_status"] = data.header["approval_status"];

          if (data.header["approval_status"] == "STRTN") {
            this.btnEdit = true;
          }

          if (this.isSubmitStatusPO === true) {
            if(res.data.enable_update_status === false){
              this.$router.push('/purchase-order');
            } else {
              this.forceUpdateTable();
              this.forceUpdateTableHistory();
              this.isSubmitStatusPO = false;
            } 
          } else {
            this.forceUpdateTable();
            this.forceUpdateTableHistory();
            this.isSubmitStatusPO = false;
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "401" });
          } else if (err.response.status == 404) {
            this.$router.push({ name: "404" });
          } else if (err.response.status == 500) {
            this.$router.push({ name: "500" });
          }
        });
    },

    fetchDataKurir() {
      this.$apiservice.setHeader();
      this.$apiservice
        .query("/purchaseorder/v1/get-detail", {
          params: { PoCode: this.$route.params.id, PrCode: this.pr_code_kurir },
        })
        .then((res) => {
          this.status_po_courier = res.data.details[0].status_vendor
          this.formStatus.production_vendor_notes = res.data.production_vendor_notes
          this.formStatus.courier_vendor_notes = res.data.courier_vendor_notes
          this.formStatus.order_received_time = res.data.order_received_time
          this.disableStatus =
            res.data.enable_update_status === true ? false : true;

          this.enableVendorKurir = res.data.enable_update_vendor_kurir;

          this.attachment_file_name = res.data.attachment_file_name;

          this.model = {};
          let data = res.data;
          for (var i in this.field) {
            let row = this.field[i];
            for (var j of Object.keys(data.header)) {
              if (row.key == j) {
                this.model[j] = data.header[j];
              }
            }
          }
          if (data.details.length > 0) {
            this.model.po_details = [];
            this.listData = [];
            for (var i in data.details) {
              let row = data.details[i];

              row["asset"] = row.asset_code + "-" + row.asset_name;
              // row["id"] = data.details.length + 1;
              if (row["stock_on_hand"] == null) {
                row["stock_on_hand"] = "-";
              }
              this.listData.push({ ...row });
            }
            this.model.po_details = this.listData;
            // this.model.id_fix = res.data.details[0].id;
            // this.model.vendor_kurir_id = res.data.details[0].vendor_kurir_id;
          }

          if (data.history.length > 0) {
            this.model.history = [];
            this.listData = [];
            this.listDataHistory = [];
            for (var i in data.history) {
              let rowHistory = data.history[i];
              this.listDataHistory.push({ ...rowHistory });
            }
            this.model.history = this.listDataHistory;
          }

          this.model["branch_id"] = data.header["branch_id"];
          this.model["ppn"] = data.header["ppn"];
          this.model["discount"] = data.header["discount"];
          this.model["grand_total"] = data.header["grand_total"];
          this.model["ppn_total"] =
            (data.header["ppn"] / 100) * data.header["grand_total"];
          this.model["total_after_ppn"] = data.header["total_after_ppn"];
          this.model["approval_status"] = data.header["approval_status"];

          if (data.header["approval_status"] == "STRTN") {
            this.btnEdit = true;
          }

          if (this.isSubmitStatusPO === true) {
            if(res.data.enable_update_status === false){
              this.$router.push('/purchase-order');
            } else {
              this.forceUpdateTable();
              this.forceUpdateTableHistory();
              this.isSubmitStatusPO = false;
            } 
          } else {
            this.forceUpdateTable();
            this.forceUpdateTableHistory();
            this.isSubmitStatusPO = false;
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "401" });
          } else if (err.response.status == 404) {
            this.$router.push({ name: "404" });
          } else if (err.response.status == 500) {
            this.$router.push({ name: "500" });
          }
        });
    },

    submitUpdatePO() {
      this.formStatus.po_code = this.$route.params.id;
      this.formStatus.po_details = this.model.po_details;

      // start x-signature-key
      var sha256 = CryptoJS.algo.SHA256.create();
      sha256.update(JSON.stringify(this.formStatus));
      var hash = sha256.finalize();
      var hashupper = hash.toString().toUpperCase();

      localStorage.setItem("signature_key", hashupper);
      // end x-signature-key

      if (this.formStatus.status === "") {
        Swal.fire({
          title: "Gagal",
          text: "Status Wajib Diisi",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } else {
        if (this.profile.data.vendor_type_name === "KURIR") {
          this.submitKurir();
        } else {
          this.submitProduksi();
        }
      }
    },

    submitProduksi() {
      this.isSubmitStatusPO = true;

      this.$apiservice.setHeader();
      this.$apiservice
        .post("purchaseorder/v1/update-po-status", this.formStatus)
        .then((res) => {
          if (res.data.error == true) {
            Swal.fire({
              title: "Gagal",
              text: res.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Oke",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            this.loading = false;
            return false;
          } else {
            this.formStatus.status = "";
            Swal.fire({
              title: "Berhasil Update Status PO",
              text: "Anda telah berhasil update status PO",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              this.fetchStatusVendor();
              this.forceUpdateTable();
              this.forceUpdateTableHistory();
              this.fetchDataProduksi();
            });
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "401" });
          } else if (err.response.status == 404) {
            this.$router.push({ name: "404" });
          } else if (err.response.status == 500) {
            this.$router.push({ name: "500" });
          } else {
            if (err.response.data.message) {
              Swal.fire({
                title: "Gagal",
                text: err.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Oke",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              this.loading = false;
              return false;
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
          this.$refs.btnSimpan.disabled = false;
        });
    },

    submitKurir() {
      this.isSubmitStatusPO = true;

      this.$apiservice.setHeader();
      this.$apiservice
        .post("purchaseorder/v1/update-po-status", this.formStatus)
        .then((res) => {
          if (res.data.error == true) {
            Swal.fire({
              title: "Gagal",
              text: res.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Oke",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            this.loading = false;
            return false;
          } else {
            this.formStatus.status = "";
            Swal.fire({
              title: "Berhasil Update Status PO",
              text: "Anda telah berhasil update status PO",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              this.fetchStatusVendor();
              this.forceUpdateTable();
              this.forceUpdateTableHistory();
              this.fetchDataKurir();
            });
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "401" });
          } else if (err.response.status == 404) {
            this.$router.push({ name: "404" });
          } else if (err.response.status == 500) {
            this.$router.push({ name: "500" });
          } else {
            if (err.response.data.message) {
              Swal.fire({
                title: "Gagal",
                text: err.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Oke",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              this.loading = false;
              return false;
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
          this.$refs.btnSimpan.disabled = false;
        });
    },

    fetchBranch() {
      this.$apiservice.setHeader();

      this.$apiservice
        .query("/reference/v1/get-branch-po", {
          params: { PoCode: this.$route.params.id },
        })
        .then((res) => {
          let data = res.data;
          this.listBranch = [];
          for (var i in data) {
            this.listBranch.push({
              value: data[i].id,
              label: data[i].text,
            });
          }
        });
    },

    fetchStatusVendor() {
      this.$apiservice.setHeader();
      this.$apiservice
        .query("/reference/v1/get-status-vendor", {
          params: { PoCode: this.$route.params.id },
        })
        .then((res) => {
          let data = res.data;
          this.isVendorStatus = [];
          for (var i in data) {
            this.isVendorStatus.push({
              value: data[i].id,
              label: data[i].text,
            });
          }
        });
    },

    btnEditVendorKurir(id, branch_id, product_id, vendor_kurir_id = "") {
      this.dialogVisibleDialogVendorKurir = true;
      this.tempIdVendorKurir.id = id;
      this.tempIdVendorKurir.branch_id = branch_id;
      this.tempIdVendorKurir.product_id = product_id;

      this.fetchVendorKurir();
      this.vendor_kurir_id = vendor_kurir_id;
    },

    fetchVendorKurir(item = null) {
      let paramKurir = {
        q: item,
        limit: 100,
        branch_id: this.tempIdVendorKurir.branch_id,
        product_id: this.tempIdVendorKurir.product_id,
      };

      // start x-signature-key
      var sha256 = CryptoJS.algo.SHA256.create();
      sha256.update(JSON.stringify(paramKurir));
      var hash = sha256.finalize();
      var hashupper = hash.toString().toUpperCase();

      localStorage.setItem("signature_key", hashupper);
      // end x-signature-key

      this.$apiservice.setHeader();

      this.$apiservice
        .post("/reference/v1/get-region-kurir-combined", {
          q: item,
          limit: 100,
          branch_id: this.tempIdVendorKurir.branch_id,
          product_id: this.tempIdVendorKurir.product_id,
        })
        .then((res) => {
          let data = res.data.data;
          this.lsVendorKurir = [];
          for (var i in data) {
            this.lsVendorKurir.push({
              label: data[i].text,
              value: data[i].id,
            });
          }
        });
    },

    cariVendorKurir(val) {
      this.loadingSelect = true;
      setTimeout(() => {
        this.loadingSelect = false;
        this.fetchVendorKurir(val.toLowerCase());
      }, 200);
    },

    handleSelect(val, key) {
      if (key == "vendor_kurir") {
        if (val != "" || val != null) {
          this.fetchVendorKurir();
        } else {
          let f_data = this.lsVendorKurir.filter((datas) => datas.value == val);

          this.vendor_name = f_data[0].label;
          this.vendor_kurir_id = f_data[0].value;

          const find_index = this.model.po_details.findIndex(
            (x) => x.id === this.tempIdVendorKurir.id
          );

          this.model.po_details[find_index].vendor_name = this.vendor_name;
          this.model.po_details[find_index].vendor_kurir_id =
            this.vendor_kurir_id;

          this.forceUpdate();
          this.forceUpdateTable();
        }
      }
    },

    btnSaveVendorKurir(val) {
      if (this.vendor_kurir_id == "") {
        this.dialogVisibleDialogVendorKurir = false;
        Swal.fire({
          title: "Gagal",
          text: "Vendor kurir tidak boleh kosong!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          this.dialogVisibleDialogVendorKurir = true;
        });
      } else {
        // disini
        let f_data = this.lsVendorKurir.filter(
          (datas) => datas.value == this.vendor_kurir_id
        );
        this.vendor_name = f_data[0].label;
        
        // const find_index = this.model.po_details.findIndex(
        //   (x) => x.id === this.tempIdVendorKurir.id
        // );

        // this.model.po_details[find_index].vendor_name = this.vendor_name;
        // this.model.po_details[find_index].vendor_kurir_id =
        //   this.vendor_kurir_id;

        for (var i = 0; i < this.model.po_details.length; i++) {
          this.model.po_details[i].vendor_name = this.vendor_name;
          this.model.po_details[i].vendor_kurir_id = this.vendor_kurir_id;
        }
          
        this.forceUpdateTable();
        this.dialogVisibleDialogVendorKurir = false;
        this.vendor_kurir_id = "";
        this.vendor_name = "";
      }
    },

    setMaxResi(e) {
      this.formStatus.receive_number = this.formStatus.receive_number.slice(
        0,
        100
      );
    },

    forceUpdateTable() {
      this.refreshTable = false;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },

    selectVendorKurir() {
      document
        .getElementsByClassName("vendorSelectKurir")[0]
        .setAttribute("id", "selectVendorKurirChange");
    },

    forceUpdateTableHistory() {
      this.refreshTableHistory = false;
      this.$nextTick(() => {
        this.refreshTableHistory = true;
      });
    },
    forceUpdate() {
      this.refreshComp = false;
      this.$nextTick(() => {
        this.refreshComp = true;
      });
    },
  },
};
</script>
