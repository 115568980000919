<template>
  <baseform />
</template>

<script>
import baseform from "@/views/pages/vendor_list_po/BaseForm.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  components: {
    baseform,
  },
  // mounted() {
  //   setCurrentPageBreadcrumbs("Detail Purchase Order", [
  //     {
  //       title: "Purchase Order",
  //       link: "#",
  //     },
  //       {
  //         title: "Purchase Order",
  //         link: "/vendor-list-po",
  //       },
  //   ]);
  // },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>
